import { AbilityBuilder, createMongoAbility } from '@casl/ability';

import DRAWER_MENU_TEXT from './constants/drawer-menu-text';
import SHORTCUT_MENU from './constants/short-cut-menu';

type UIPermission = {
  screenPermission: string[];
  clickableComponentPermission: string[];
  editableComponentPermission: string[];
  viewableComponentPermission: string[];
};

// define abilities
export default function defineAbilityFor(permission: UIPermission) {
  const { clickableComponentPermission, editableComponentPermission, screenPermission, viewableComponentPermission } =
    permission;
  const { can, build } = new AbilityBuilder(createMongoAbility);
  if (screenPermission) {
    screenPermission.forEach((permission) => {
      can('access', permission);
    });

    const itemMenuElements = DRAWER_MENU_TEXT.ITEM_MENU.items.map((item) => {
      return item.permission;
    });

    if (itemMenuElements.some((itemMenuElement) => screenPermission.includes(itemMenuElement))) {
      can('access', DRAWER_MENU_TEXT.ITEM_MENU.permission);
    }

    const shippingMenuElements = DRAWER_MENU_TEXT.SHIPPING_MENU.items.map((item) => {
      return item.permission;
    });

    if (shippingMenuElements.some((shippingMenuElement) => screenPermission.includes(shippingMenuElement))) {
      can('access', DRAWER_MENU_TEXT.SHIPPING_MENU.permission);
    }

    const arrivalMenuElements = DRAWER_MENU_TEXT.ARRIVAL_MENU.items.map((item) => {
      return item.permission;
    });

    if (arrivalMenuElements.some((arrivalMenuElement) => screenPermission.includes(arrivalMenuElement))) {
      can('access', DRAWER_MENU_TEXT.ARRIVAL_MENU.permission);
    }

    const receiveMenuElements = DRAWER_MENU_TEXT.RECEIVE_MENU.items.map((item) => {
      return item.permission;
    });

    if (receiveMenuElements.some((receiveMenuElement) => screenPermission.includes(receiveMenuElement))) {
      can('access', DRAWER_MENU_TEXT.RECEIVE_MENU.permission);
    }

    const emulatorMenuElements = DRAWER_MENU_TEXT.EMULATOR_MENU.items.map((item) => {
      return item.permission;
    });

    if (emulatorMenuElements.some((emulatorMenuElement) => screenPermission.includes(emulatorMenuElement))) {
      can('access', DRAWER_MENU_TEXT.EMULATOR_MENU.permission);
    }

    if (SHORTCUT_MENU.item.some((shortcutMenu) => screenPermission.includes(shortcutMenu))) {
      can('access', SHORTCUT_MENU.permission);
    }
  }

  if (clickableComponentPermission) {
    clickableComponentPermission.forEach((permission) => {
      can('click', permission);
    });
  }

  if (editableComponentPermission) {
    editableComponentPermission.forEach((permission) => {
      can('edit', permission);
    });
  }

  if (viewableComponentPermission) {
    viewableComponentPermission.forEach((permission) => {
      can('view', permission);
    });
  }

  return build();
}
